import { Store } from '../interfaces/heatmap'

const findStoreByIdInBranchs = (stores: Store[], storeId: number): Store | null => {
  if (stores.length === 0) {
    return null
  }

  for (const store of stores) {
    if (store.branchs.length === 0) {
      continue
    }

    for (const branch of store.branchs) {
      if (branch.store_id === storeId) {
        return branch
      }
    }
  }

  return null
}

export { findStoreByIdInBranchs }
