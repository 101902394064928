import React, { ChangeEvent, useState } from 'react'
import { lastValueFrom } from 'rxjs'
import { css } from '@emotion/css'
import { AppPluginMeta, GrafanaTheme2, PluginConfigPageProps, PluginMeta } from '@grafana/data'
import { getBackendSrv } from '@grafana/runtime'
import { Button, Field, FieldSet, Input, SecretInput, useStyles2 } from '@grafana/ui'
import { testIds } from '../testIds'

export type AppPluginSettings = {
  apiUrl?: string
  apiKey?: string
  apiHeatmapImageUrl?: string
  apiHeatmapImageKey?: string
}

type State = {
  // The URL to reach our custom API.
  apiUrl: string
  // Tells us if the API key secret is set.
  isApiKeySet: boolean
  // A secret key for our custom API.
  apiKey: string
  apiHeatmapImageUrl: string
  apiHeatmapImageKey: string
  isApiHeatmapImageKeySet: boolean
}

export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<AppPluginSettings>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const styles = useStyles2(getStyles)
  const { enabled, pinned, jsonData } = plugin.meta

  const [state, setState] = useState<State>({
    apiUrl: jsonData?.apiUrl || '',
    apiKey: jsonData?.apiKey || '',
    isApiKeySet: Boolean(jsonData?.apiKey),
    apiHeatmapImageUrl: jsonData?.apiHeatmapImageUrl || '',
    apiHeatmapImageKey: jsonData?.apiHeatmapImageKey || '',
    isApiHeatmapImageKeySet: Boolean(jsonData?.apiHeatmapImageKey),
  })

  const onResetApiKey = () =>
    setState((prevState) => ({
      ...prevState,
      apiKey: '',
      isApiKeySet: false,
    }))

  const onResetApiHeatmapImageKey = () =>
    setState((prevState) => ({
      ...prevState,
      apiHeatmapImageKey: '',
      isApiHeatmapImageKeySet: false,
    }))

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value.trim(),
    }))
  }

  return (
    <div data-testid={testIds.appConfig.container}>
      <FieldSet label="API Settings">
        <Field label="API Url" description="">
          <Input
            width={60}
            name="apiUrl"
            id="config-api-url"
            data-testid={testIds.appConfig.apiUrl}
            value={state.apiUrl}
            placeholder={`E.g.: http://my-api.com/api/v1`}
            onChange={onChange}
          />
        </Field>

        <Field label="API Key">
          <SecretInput
            width={60}
            id="config-api-key"
            data-testid={testIds.appConfig.apiKey}
            name="apiKey"
            value={state.apiKey}
            isConfigured={state.isApiKeySet}
            placeholder={'Your secret API key'}
            onChange={onChange}
            onReset={onResetApiKey}
          />
        </Field>

        <div className={styles.marginTop}>
          <Button
            type="submit"
            data-testid={testIds.appConfig.submit}
            onClick={() =>
              updatePluginAndReload(plugin.meta.id, {
                enabled,
                pinned,
                jsonData: {
                  apiUrl: state.apiUrl,
                  apiKey: state.apiKey,
                  apiHeatmapImageUrl: state.apiHeatmapImageUrl,
                  apiHeatmapImageKey: state.apiHeatmapImageKey,
                },
              })
            }
            disabled={Boolean(!state.apiUrl)}
          >
            Save settings
          </Button>
        </div>
      </FieldSet>

      {/* https://dmpapi2.trueid-preprod.net/dsiot-image */}

      <FieldSet label="API Heatmap image Settings">
        <Field label="API Heatmap image Url" description="">
          <Input
            width={60}
            name="apiHeatmapImageUrl"
            id="config-api-heatmap-image-url"
            data-testid={testIds.appConfig.apiHeatmapImageUrl}
            value={state.apiHeatmapImageUrl}
            placeholder={`API Heatmap Image E.g.: http://my-api.com/api/v1`}
            onChange={onChange}
          />
        </Field>

        <Field label="API Heatmap Image Key">
          <SecretInput
            width={60}
            id="config-api-heatmap-image-key"
            data-testid={testIds.appConfig.apiHeatmapImageKey}
            name="apiHeatmapImageKey"
            value={state.apiHeatmapImageKey}
            isConfigured={state.isApiHeatmapImageKeySet}
            placeholder={'Your secret API Heatmap Image key'}
            onChange={onChange}
            onReset={onResetApiHeatmapImageKey}
          />
        </Field>

        <div className={styles.marginTop}>
          <Button
            type="submit"
            data-testid={testIds.appConfig.submit}
            onClick={() =>
              updatePluginAndReload(plugin.meta.id, {
                enabled,
                pinned,
                jsonData: {
                  apiUrl: state.apiUrl,
                  apiKey: state.apiKey,
                  apiHeatmapImageUrl: state.apiHeatmapImageUrl,
                  apiHeatmapImageKey: state.apiHeatmapImageKey,
                },
              })
            }
            disabled={Boolean(!state.apiHeatmapImageUrl)}
          >
            Save settings
          </Button>
        </div>
      </FieldSet>
    </div>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
})

const updatePluginAndReload = async (pluginId: string, data: Partial<PluginMeta<AppPluginSettings>>) => {
  try {
    await updatePlugin(pluginId, data)

    // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
    // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
    window.location.reload()
  } catch (e) {
    console.error('Error while updating the plugin', e)
  }
}

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = await getBackendSrv().fetch({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  })

  return lastValueFrom(response)
}
