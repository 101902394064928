import React, { useCallback, useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import { useForm } from 'react-hook-form'
import { GrafanaTheme2 } from '@grafana/data'
import { Icon, Input, useStyles2 } from '@grafana/ui'
import { useAppSettingContext } from '../../../contexts/appSettingContext'
import { SingleStoreList } from '../../storeList'
import { FormattedStore, FormattedParentStore, FilterSearchOptions } from '../../../interfaces/settingUser'
import { EditStoreState, FormStoreSettingRef } from '../../../interfaces/heatmap'
import { findStoreByIdInBranchs } from '../../../utils/utils.storeData'

type FormValues = {
  storeIds: number
  accountNumber: string
  name: string
  status: string
  role: string
  userId: string
}

interface SearchProps {
  stores: FormattedStore[]
  handleAddStore?: (parentStore: FormattedParentStore) => void
  addStore?: boolean
  setAddStoreState: (state: boolean) => void
  setEditStoreSetting: ({ isEdit, storeId }: EditStoreState) => void
  filterSearchOptions: FilterSearchOptions
  formStoreRef: React.RefObject<FormStoreSettingRef>
}

const SearchStorePage: React.FC<SearchProps> = ({
  stores,
  handleAddStore,
  addStore,
  setAddStoreState,
  setEditStoreSetting,
  filterSearchOptions,
  formStoreRef,
}) => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()

  const effectRan = useRef(false)

  const [filteredStores, setFilteredStores] = useState<FormattedStore[]>(stores)
  const [highlightText, setHighlightText] = useState<string>('')

  const { control, getValues, setValue, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      storeIds: undefined,
      accountNumber: '',
      name: '',
      status: '',
      userId: '',
    },
  })

  const queryUserData = useCallback(async () => {}, [t3StoreApiConfig, getValues])

  const handleSearchStore = (e: React.FormEvent<HTMLInputElement>) => {
    const inputText = (e.target as HTMLInputElement).value
    if (inputText === '') {
      setFilteredStores(stores)
    } else {
      const result = stores
        .map((store) => ({
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: store.branchs.filter(
            (st) =>
              st.store_name.toLowerCase().includes(inputText.toLowerCase()) ||
              st.store_id.toString().includes(inputText)
          ),
        }))
        .filter((store) => store.store_name.length > 0)

      setFilteredStores(result)
    }
    setHighlightText(inputText)
  }

  const onSubmit = useCallback(() => {
    queryUserData()
  }, [queryUserData])

  useEffect(() => {
    if (!effectRan.current) {
      queryUserData()
      effectRan.current = true
    }
  }, [queryUserData])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'storeIds') {
        const storeIds = getValues().storeIds
        const filterParentId = stores.find((item) => item.store_id === +storeIds)
        const result = findStoreByIdInBranchs(stores, storeIds)
        const store_parent_name = result?.store_parent_name || undefined

        if (filterParentId) {
          setEditStoreSetting({ isEdit: false, storeId: undefined, store_parent_name })
          setAddStoreState(false)
        } else {
          setEditStoreSetting({ isEdit: true, storeId: +storeIds, store_parent_name })
          setAddStoreState(true)
        }

        onSubmit()
      }
    })
    return () => subscription.unsubscribe()
  }, [onSubmit, watch])

  return (
    <section className={style.sidebarContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.space}>
          <Input placeholder="input store name..." prefix={<Icon name="search" />} onChange={handleSearchStore} />
        </div>
        {filteredStores?.length > 0 && (
          <SingleStoreList
            data={filteredStores}
            control={control}
            name="storeIds"
            searchingText={highlightText}
            setDefaultValue={setValue}
            handleAddStore={handleAddStore}
            addStore={addStore}
            filterSearchOptions={filterSearchOptions}
            setEditStoreSetting={setEditStoreSetting}
            formStoreRef={formStoreRef}
          />
        )}
      </form>
    </section>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  sidebarContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    overflow: hidden;
    padding: ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(8)} ${theme.spacing(1)};
  `,
  space: css`
    margin-bottom: ${theme.spacing(2)};
  `,
})

export default SearchStorePage
