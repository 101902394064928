import { QueriesDataResponse } from '../interfaces/apiQueries'

const mapQueriesValue = <T>(data: QueriesDataResponse | null | undefined, sortBy?: keyof T): T[] => {
  if (!data || data.values?.length === 0 || data.columns?.length === 0) {
    return []
  }

  const mappedData = data.values.map((value) =>
    data.columns.reduce((acc, column: string, index: number) => {
      acc[column as keyof T] = value[index]
      return acc
    }, {} as T)
  )

  if (sortBy) {
    return mappedData.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1
      }
      if (a[sortBy] > b[sortBy]) {
        return 1
      }
      return 0
    })
  } else {
    return mappedData
  }
}

const mapSelectInputData = <T>(
  data: QueriesDataResponse | null | undefined,
  field: { label: keyof T; value: keyof T }
): Array<{ label: any; value: any }> => {
  const mapValue = mapQueriesValue<T>(data)

  return mapValue?.map((item: T) => ({ label: item[field.label], value: item[field.value] }))
}

export { mapQueriesValue, mapSelectInputData }
