const MODAL_TYPE_NAME = {
  CONFIRM: 'confirm',
  EDIT: 'edit',
  ERROR: 'error',
  API_ERROR: 'api_error',
  FIELDS_REQUIRE: 'fields_require',
  API_ERROR_MESSAGE: 'api_error_message',
}

const MODAL_TYPE = {
  [MODAL_TYPE_NAME.CONFIRM]: {
    title: 'Confirm',
    message: 'Are you sure want to save your {{message}}?',
  },
  [MODAL_TYPE_NAME.EDIT]: {
    title: 'Confirm',
    message: 'Are you sure want to {{message}}?',
  },
  [MODAL_TYPE_NAME.ERROR]: {
    title: 'Oooops!',
    message: 'Please check your {{message}} again.',
  },
  [MODAL_TYPE_NAME.API_ERROR]: {
    title: 'Oooops!',
    message: 'Sorry, Something went wrong.',
  },
  [MODAL_TYPE_NAME.FIELDS_REQUIRE]: {
    title: 'Oooops!',
    message: 'Please check mandatory fields.',
  },
  [MODAL_TYPE_NAME.API_ERROR_MESSAGE]: {
    title: 'Oooops!',
    message: '{{message}}',
  },
}

const getModalMessage = (type: string, replaceValue = 'interval'): string | undefined => {
  const modal = MODAL_TYPE[type]
  if (!modal) {
    return undefined
  }

  return modal.message.replace('{{message}}', replaceValue)
}

export { MODAL_TYPE_NAME, MODAL_TYPE, getModalMessage }
