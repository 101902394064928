import { T3StoreApiConfig } from '../interfaces/apiConfig'
import {
  AddStoreImages,
  ErrorAddStoreImages,
  StoreHeatmap,
  StoreHeatmapPicture,
  DeleteStoreHeatmapPicture,
} from '../interfaces/heatmap'
import { createApiConfig } from './peoplecountingApi'
import { v4 as uuidv4 } from 'uuid'
import { fetchPost } from '../utils/utils.apiControl'
import { getQueries } from './queriesApi'
import { mapQueriesValue } from '../utils/utils.mapValue'

const addStoreImages = async (storeId: number, formData: FormData, t3StoreApiConfig: T3StoreApiConfig) => {
  let result: AddStoreImages | null = null,
    error: ErrorAddStoreImages | null = null

  try {
    const response = await fetch(`${t3StoreApiConfig.apiUrl}/stores/${storeId}/image`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: t3StoreApiConfig.apiKey,
      },
    })

    if (response?.ok) {
      result = await response.json()
    } else if (response.status === 400) {
      const errorData = await response.json()
      error = { message: errorData?.error?.message || '' }
    }
  } catch (err: any) {
    console.log('Error addStoreImages : ', err)
    error = err
  }

  return { result, error }
}

const deleteStoreImages = async (storeId: number, imageList: string[], t3StoreApiConfig: T3StoreApiConfig) => {
  let result: any | null = null,
    error: ErrorAddStoreImages | null = null

  try {
    const response = await fetch(`${t3StoreApiConfig.apiUrl}/stores/${storeId}/image`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: t3StoreApiConfig.apiKey,
      },
      body: JSON.stringify({ image_list: imageList }),
    })

    if (!response.ok) {
      const errorData = await response.json()
      error = { message: errorData?.error?.message || '' }
    }

    result = await response.json()
  } catch (err: any) {
    console.log('Error deleteStoreImages : ', err)
    error = err
  }

  return { result, error }
}

const addStoreHeatmapApi = async (storeHeatmapParams: StoreHeatmap[], t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error addStoreHeatmapApi : ', err)
    error = err
  }

  return { result, error }
}

const updateStoreHeatmapApi = async (storeHeatmapParams: StoreHeatmap[], t3StoreApiConfig: T3StoreApiConfig) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap_update',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error updateStoreHeatmapApi : ', err)
    error = err
  }

  return { result, error }
}

const addStoreHeatmapPictureApi = async (
  storeHeatmapParams: StoreHeatmapPicture[],
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap_picture',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error addStoreHeatmapPictureApi : ', err)
    error = err
  }

  return { result, error }
}

const deleteStoreHeatmapPictureApi = async (
  storeHeatmapParams: DeleteStoreHeatmapPicture[],
  t3StoreApiConfig: T3StoreApiConfig
) => {
  let result = null,
    error = null

  try {
    result = await fetchPost(
      `${t3StoreApiConfig.apiUrl}/injection`,
      {
        event_timestamp: new Date().getTime(),
        event_name: 'm_store_heatmap_picture_delete',
        event_id: uuidv4(),
        country_iso2: 'th',
        event_parameter: storeHeatmapParams,
        x_correlation_id: uuidv4(),
      },
      createApiConfig(t3StoreApiConfig)
    )
  } catch (err: any) {
    console.log('Error addStoreHeatmapPictureApi : ', err)
    error = err
  }

  return { result, error }
}

const getHeatmapImages = async (storeId: number, t3StoreApiConfig: T3StoreApiConfig) => {
  const { result: storesResult } = await getQueries(
    { q: 'iot.func_get_store_heatmap_picture_by_id', param_input: storeId },
    t3StoreApiConfig
  )
  return mapQueriesValue<any>(storesResult?.data)
}

export {
  addStoreImages,
  addStoreHeatmapApi,
  addStoreHeatmapPictureApi,
  deleteStoreImages,
  getHeatmapImages,
  updateStoreHeatmapApi,
  deleteStoreHeatmapPictureApi,
}
