import { FileWithPath, StoreHeatmapPicture } from '../interfaces/heatmap'
import { T3StoreApiConfig } from '../interfaces/apiConfig'

const createBlobFromFileName = (fileName: string): Blob => {
  const mimeType = getMimeTypeFromFileName(fileName)
  return new Blob([fileName], { type: mimeType })
}

const fetchImageAsFile = (
  fileName: string,
  imageData: StoreHeatmapPicture,
  t3StoreApiConfig: T3StoreApiConfig,
  storeId: number
): FileWithPath => {
  const blob = createBlobFromFileName(fileName)
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  }) as FileWithPath

  file.path = `${t3StoreApiConfig.apiHeatmapImageUrl}/stores/${storeId}/image/${imageData.picture_name}`
  return file
}

const getMimeTypeFromFileName = (fileName: string): string => {
  const extension = fileName?.split('.').pop()?.toLowerCase()
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    default:
      return 'application/octet-stream'
  }
}

export { fetchImageAsFile, getMimeTypeFromFileName, createBlobFromFileName }
