import React, { useEffect, useState, useRef } from 'react'

import { PluginPage } from '@grafana/runtime'

import { getQueries } from '../../apis/queriesApi'
import { useAppSettingContext } from '../../contexts/appSettingContext'
import { mapQueriesValue } from '../../utils/utils.mapValue'
import { SearchStorePage, FormStoreSettingPage } from '../../components/heatmap'
import { FormattedStore, Store, FormattedParentStore, FilterSearchOptions } from '../../interfaces/settingUser'
import { EditStoreState, FormStoreSettingRef } from '../../interfaces/heatmap'
import { useUserProfileContext } from '../../contexts/userProfileContext'
import { GrafanaTheme2 } from '@grafana/data'
import { css } from '@emotion/css'
import { useStyles2 } from '@grafana/ui'

export enum SettingPageEnum {
  ADD = 'add',
}

const HeatmapStoreSetting = () => {
  const style = useStyles2(getStyles)
  const { t3StoreApiConfig } = useAppSettingContext()
  const { userProfile } = useUserProfileContext()

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true)
  const [settingPage, setSettingPage] = useState<SettingPageEnum>(SettingPageEnum.ADD)
  const [storesData, setStoresData] = useState<FormattedStore[]>([])
  const [editStoreSetting, setEditStoreSetting] = useState<EditStoreState>({
    isEdit: false,
    storeId: undefined,
    store_parent_name: undefined,
  })
  const [addStoreState, setAddStoreState] = useState<boolean>(false)
  const [parentStoreData, setParentStoreData] = useState<FormattedParentStore | null>(null)
  const [filterSearchOptions, setFilterSearchOptions] = useState<FilterSearchOptions>({
    isClickCancel: false,
  })
  const formStoreRef = useRef<FormStoreSettingRef>(null)

  const formatStores = (data: Store[]): FormattedStore[] => {
    const storeMap: { [key: number]: FormattedStore } = {}

    data.forEach((store) => {
      if (store.store_type === 'store') {
        storeMap[store.store_id] = {
          store_id: store.store_id,
          store_name: store.store_name,
          branchs: [],
        }
      }
    })
    data.forEach((store) => {
      if (store.store_type === 'branch' && store.store_parent_id !== null) {
        const parentStore = storeMap[store.store_parent_id]
        if (parentStore) {
          parentStore.branchs.push({
            store_id: store.store_id,
            store_name: store.store_name,
            store_parent_id: store.store_parent_id,
            store_parent_name: parentStore.store_name,
            status: store?.status === 'enable' ? true : false,
            branchs: [],
          })
        }
      }
    })

    return Object.values(storeMap)
  }

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        // Fetch store data
        const { result: storesResult } = await getQueries({ q: 'iot.func_get_stores' }, t3StoreApiConfig)
        const storesData = mapQueriesValue<Store>(storesResult?.data)
        const formattedStores = formatStores(storesData)
        if (isMounted) {
          setStoresData(formattedStores)
          setIsLoadingPage(false)
        }
      } catch (error) {
        console.error('Failed to fetch data', error)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [t3StoreApiConfig, addStoreState])

  const handleAddStore = ({ storeId, storeName }: FormattedParentStore) => {
    setSettingPage(SettingPageEnum.ADD)
    setParentStoreData({ storeId, storeName })
    setAddStoreState(true)
  }

  const renderPageContent = () => {
    switch (settingPage) {
      case SettingPageEnum.ADD:
        if (addStoreState) {
          return (
            <FormStoreSettingPage
              setSettingPage={setSettingPage}
              userProfile={userProfile}
              parentStoreData={parentStoreData}
              setAddStoreState={setAddStoreState}
              editStoreSetting={editStoreSetting}
              setFilterSearchOptions={setFilterSearchOptions}
              formStoreRef={formStoreRef}
            />
          )
        } else {
          return null
        }
      default:
        return null
    }
  }

  return (
    <PluginPage renderTitle={() => <h1>Store Setting</h1>}>
      {!isLoadingPage && (
        <section className={style.pageWrapper}>
          <div className={style.mainContainer}>
            <SearchStorePage
              stores={storesData}
              handleAddStore={handleAddStore}
              addStore={settingPage === SettingPageEnum.ADD ? true : false}
              setAddStoreState={setAddStoreState}
              setEditStoreSetting={setEditStoreSetting}
              filterSearchOptions={filterSearchOptions}
              formStoreRef={formStoreRef}
            />
            {renderPageContent()}
          </div>
        </section>
      )}
    </PluginPage>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  pageWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(6)};
    height: 100%;
  `,
  mainContainer: css`
    display: grid;
    grid-template-columns: minmax(378px, auto) 1fr;
    gap: ${theme.spacing(4)};
    height: 100%;
  `,
})

export default HeatmapStoreSetting
