import React, { createContext, useContext } from 'react'
import { AppPluginMeta } from '@grafana/data'
import { T3StoreApiConfig } from '../interfaces/apiConfig'

interface I18nProvider {
  appMeta: AppPluginMeta
  children: any
}

interface AppSettingContextType {
  t3StoreApiConfig: T3StoreApiConfig
}

const AppSettingContext = createContext<AppSettingContextType>({
  t3StoreApiConfig: {
    apiUrl: '',
    apiKey: '',
    apiHeatmapImageUrl: '',
    apiHeatmapImageKey: '',
  },
})

export const AppSettingProvider: React.FC<I18nProvider> = ({ appMeta, children }) => {
  const contextData = {
    t3StoreApiConfig: {
      apiUrl: appMeta?.jsonData?.apiUrl || '',
      apiKey: appMeta?.jsonData?.apiKey || '',
      apiHeatmapImageUrl: appMeta?.jsonData?.apiHeatmapImageUrl || '',
      apiHeatmapImageKey: appMeta?.jsonData?.apiHeatmapImageKey || '',
    },
  }

  return <AppSettingContext.Provider value={contextData}>{children}</AppSettingContext.Provider>
}

export const useAppSettingContext = () => {
  return useContext(AppSettingContext)
}
